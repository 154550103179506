<template>
  <div id="stageline-landing-page-component">
    <notification-manager v-if="showNotification" />
    <ct-centered-spinner v-if="!loaded" />

    <div v-if="loaded" class="load-companies-container">
      <div class="load-companies">
        <manage-your-business-svg
          alt="Manage your business"
          class="img-desktop"
        />
        <manage-your-business-svg-box
          alt="Manage your business"
          class="img-mobile"
        />
        <select-business />
      </div>
      <stageline-no-companies />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StagelineLandingPage',
  components: {
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    ManageYourBusinessSvg:    () => import('@images/illustrations/stageline/ManageYourBusiness.svg'),
    ManageYourBusinessSvgBox: () => import('@images/illustrations/stageline/ManageYourBusinessBox.svg'),
    NotificationManager:       () => import('@/components/NotificationManager'),
    SelectBusiness:           () => import('@/components/StagelineV2/SelectBusiness'),
    StagelineNoCompanies:     () => import('@/components/StagelineV2/StagelineNoCompanies'),
  },
  data () {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'accountCompanies',
    ]),
    showNotification() {
      return !this.loading
    },
  },
  async mounted() {
    if (!this.accountCompanies.length) await this.getAccountCompanies()
    this.loaded = true
  },
  methods: {
    ...mapActions('stageline', [
      'getAccountCompanies',
    ]),
  },
}
</script>

<style scoped lang="scss">
// Palette
$black1: #231F20;
$gray1: #76787A;
$gray2-light3: #E9E9E9;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-6, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

// Typography: Fonts
h3, h4, p {
  margin: 0;
}

h3 {
  @include typography(
    $font-size: 2.0em,
    $font-weight: $ct-ui-font-weight-7
  );
}

h4 {
  @include typography(
    $font-size: 1.5em,
    $font-weight: $ct-ui-font-weight-7
  );
}

p {
  @include typography();
}

// Normalize
* {
  margin: 0;
  padding: 0;
}

// Component
#stageline-landing-page-component {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .website-name {
    position: absolute;
    top: 1.25em;
    left: 1.25em;
    margin-top: 3.75em;
    color: $gray1;
  }

  .load-companies-container {
    width: 100%;
    max-width: 62.5em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .load-companies {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;
      column-gap: 3.5em;

      svg {
        width: 100%;
        max-width: 20.625em;
        height: 100%;
        max-height: 21.125em;

        &.img-mobile {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #stageline-landing-page-component {
    padding: 2.0em;

    .website-name {
      display: none;
    }

    .load-companies-container {
      padding: 0;

      .load-companies {
        padding-top: 0;

        svg {
          width: 100%;
          max-width: 15.625em;
          height: 100%;
          min-height: 16em;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #stageline-landing-page-component {
    padding: 1.0em 1.25em;

    .load-companies-container {
      max-width: unset;
      padding: 0;

      .load-companies {
        flex-flow: column;
        column-gap: 0;
        row-gap: 1.0em;

        svg {
          align-self: center;
          width: 100%;
          max-width: 19.9375em;
          height: 100%;
          min-height: 11.4375em;

          &.img-mobile {
            display: block;
          }

          &.img-desktop {
            display: none;
          }
        }
      }
    }
  }
}
</style>
